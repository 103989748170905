import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from '@emotion/styled'

import BasicHero from '../components/BasicHero'
import BlogCard from "../components/BlogCard"

const Container = styled.section`
  max-width: 1200px;
  margin: 100px auto 200px;
  padding: 0 16px;
`


const Blog = ({data}) => {
  const posts = data.posts.edges;
  return (
    <Layout headerAlt={true}>
      <SEO title="NATA News" />

      <BasicHero 
        fluid={data.hero.childImageSharp.fluid}  
        title={"temp image"} 
        heroTitle={"NATA News"}
        heroBorder
      />

      <Container>
        { posts.map( ( { node }, index ) => (
            
            <BlogCard 
              key={index} 
              post={node} 
            />
          
        ))}
      </Container>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
    query blogpageQuery($limit: Int!, $skip: Int!) {
      posts: 
        allWordpressPost(
          limit: $limit,
          skip: $skip
        ) {
          edges {
            node {
              slug
              title
              excerpt
              author {
                name
              }
              date(formatString: "MMMM DD, YYYY")
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      hero: file(relativePath: { eq: "blog-hero-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }      
    }
`