import React from 'react'
import BgImage from '../components/BgImage'
import styled from '@emotion/styled'

import Wave from '../assets/images/icons/wave-page-divider.svg'

const HeroBorder = styled.div`
  width: 100%;
  zIndex: 10;
  position: absolute;
  bottom: -5px;
  height: auto;

  img {
    width: 100%;
  }
`

const BasicHero = ({fluid, title, heroTitle, heroBorder}) => (
    <header style={{position: 'relative'}}>
      <BgImage
        fluid={fluid}
        title={title}
        height={'550px'}
        mobileHeight={'250px'}
        overlayColor={'rgba(0,0,0,0.6)'}
        style={{ display: 'block' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '550px', textAlign: 'center'}}>
            <h1 style={{
              fontSize: '48px',
              color: 'white',
              margin: '0',
            }}>{heroTitle}</h1>
        </div>
      </BgImage>

      { heroBorder &&
        <HeroBorder>
          <img src={Wave} alt='page divider' />
        </HeroBorder>
      }
      
    </header>
  
)

export default BasicHero