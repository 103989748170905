import React from "react";
import Img from 'gatsby-image';
import { Link } from 'gatsby'

import styled from "@emotion/styled"


const StyledDate = styled.span`
  color: #8C8C8C;
`

const StyledCard = styled.article`
  margin: 100px 0;
  position: relative;
  display: flex;

  @media screen and (max-width: 996px) {
    flex-direction: column;
    margin: 50px 0;
  }
`

const CardImage = styled.div`
  flex: 1 1 785px;
  box-shadow: 6px 6px 0px #9AC355;
  z-index: 2;

  @media screen and (max-width: 996px) {
    flex: 1 100%;
    box-shadow: none;
  }
`

const CardContent = styled.div`
  background-color: #48A49D;
  color: #FFFFFF;
  flex: 1 1 600px;
  margin-top: 30px;
  padding: 50px 50px 50px 100px;
  margin-left: -50px;
  margin-bottom: -40px;
  z-index: 1;

  h1 {
    margin: 0;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 996px) {
    flex: 1 100%;
    margin-top: 0;
    padding: 30px;
    margin-left: 0;
    margin-bottom: 0;
  }
`

const StyledFullLink = styled(Link)`
  display: inline-block;
  color: white;
  border: 2px solid white;
  padding: 15px 25px;
  border-radius: 50px;
  background: none;
  transition: all 0.3s;

  :hover {
    cursor: pointer;
    background: white;
    color: #48A49D;
  }
`

const CardFooter = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`


const BlogCard = ({ post }) => {
  const imageSrc = post.featured_media !== null && post.featured_media.localFile.childImageSharp.fluid;
  const date = post.date;
  const author = post.author;
  
  return (
    <StyledCard>
      <CardImage>
      { imageSrc && 
          <Img style={{height: '100%', width: '100%'}} fluid={imageSrc} />
      }
      </CardImage>

      <CardContent>
        <h1 dangerouslySetInnerHTML={{__html: post.title}} />
        <div dangerouslySetInnerHTML={{__html: post.excerpt }} />
        <CardFooter>
          <StyledFullLink href={`/news/${post.slug}`}>Keep Reading</StyledFullLink>
          <div>
            <div>{author.name}</div>
            <div>{date}</div>
          </div>
        </CardFooter>
      </CardContent>
    </StyledCard>
  )
};

export default BlogCard;