import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import styled from "@emotion/styled";

const Parent = styled.div`
  position: relative;

  background-color: ${({ bc }) => bc};
  background: ${({gradient}) => gradient};

  overflow: hidden;
 
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 10;
`;

const BgImage = ({
  fluid,
  title,
  height,
  mobileHeight,
  overlayColor,
  overlayGradient,
  children,
  maskMode,
  ...props
}) => (
  <Parent bc={overlayColor} gradient={overlayGradient} {...props}>
    <ContentContainer>{children}</ContentContainer>
    <Img style={{
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    }}
      fluid={fluid}
      title={title}
      height={height}
      mobileHeight={mobileHeight}
    />
  </Parent>
);

BgImage.propTypes = {
  fluid: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  mobileHeight: PropTypes.string,
  overlayColor: PropTypes.string,
  overlayGradient: PropTypes.string,
  children: PropTypes.node,
};

BgImage.defaultProps = {
  height: null,
  mobileHeight: null,
  overlayColor: 'transparent',
  overlayGradient: 'none',
  children: null,
};

export default BgImage;